#test-icon {
  position: static;
}


.desktop-icon-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  position: relative;
  align-items: center;
  width: max-content;
  max-width: 16em;
  margin: .25em;
  flex-basis: 0;
}

.desktop-icon:hover, .desktop-icon-label-wrapper:hover {
  cursor: pointer;
}

.desktop-icon {
  /* border: 1px solid black; */
  position: relative;
  z-index: 2;
  user-select: none;
}

.desktop-icon-label-wrapper {
  background-color: var(--comp2);
  /* position: absolute; */
  top: 66px;
  left: 50%;
  /* transform: translateX(-50%); */
  padding: 3px;
  z-index: 1;
  width: max-content;
  max-width: 6em;
  overflow-wrap: break-word;
  word-break:break-word;
  display: flex;
}

.desktop-icon-label-wrapper:active {
  background-color: var(--comp1);
}

.desktop-icon-label {
  user-select: none;
  padding: 2px 10px 2px 10px;
}
