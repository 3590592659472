:root {
  --background: #e5e7fd;
  --comp1: #fde5fa;
  --comp2: #f1fde5;
  /* --comp2: red; */
}

#projects-list-wrapper {
  background-color: var(--comp2);
  flex-direction: column;
  display: flex;
  align-items: center;
}

.project-list-url-bar {
  display: flex;
  justify-content: flex-start;
  word-wrap: none;
  max-height: 2em;
  width: 90%;
  padding: 3px;
}

.project-list-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.flex4 {
  flex: 4;
}

.flex5 {
  flex: 5;
}
.flex6{
  flex: 6;
}
.flex7 {
  flex: 7;
}
.flex8 {
  flex: 8;
}
.flex9 {
  flex: 9;
}
.flex10 {
  flex: 10;
}

.background1 {
  background-color: white;
}

.background2 {
  background-color: whitesmoke;
}

.project-info-section {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 80%;
  height: fit-content;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5px;
  margin-bottom: 5px;
  margin-top: 10px;
  overflow-y: auto;
}

.project-info-divider {
  width: 100%;
}

ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 90%;
  margin:0;
  margin-top: 5px;
  margin-bottom: 5px;
}

li {
  list-style-type: none;
  cursor: pointer;
}

.project-row-icon {
  width: 16px;
  height: 16px;
  align-self: center;
  padding: 5px;
}

.project-list-dates {
  padding-left: 5px;
  padding-right: 5px;
}

.project-list {
  overflow-y: scroll;
}

.project-list-max-height {
  max-height: 25%;
}

.vertical-divider {
  width: 1px;
  height: 100%;
  background-color: black;
}